:root {
    --box-sizing: border-box;
}

*,html{
    box-sizing: border-box;
}

*{
    scroll-behavior: smooth;
}




* {
    box-sizing: border-box;
    font-family: poppins;
}

.h1l {
    font-weight: bold;
    margin: 0;
}


.pls {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

.spanl {
    font-size: 12px;
}

a.social {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

.buttonl {
    border-radius: 20px;
    border: 1px solid #02B9ED;
    background-color: #02B9ED;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    margin-top: 10px;
}

.buttonl:active {
    transform: scale(0.95);
}

.buttonl:focus {
    outline: none;
}

.buttonl--ghost {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;

}

.form {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
    height: 100%;
    text-align: center;
}

.inputl {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}

.container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 7px 7px rgba(0,0,0,0.25), 
            0 5px 5px rgba(0,0,0,0.22);
    position: relative;
    overflow: hidden;
    width: 1024px;
    
    max-width: 100%;
    min-height: 480px;
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

.container.right-panel-active .sign-in-container {
    transform: translateX(100%);
}

.sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

@keyframes show {
    0%, 49.99% {
        opacity: 0;
        z-index: 1;
    }
    
    50%, 100% {
        opacity: 1;
        z-index: 5;
    }
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.container.right-panel-active .overlay-container{
    transform: translateX(-100%);
}

.overlay {
    background: #016D8D;
    background: -webkit-linear-gradient(to right, #20CAFE, #016D8D);
    background: linear-gradient(to right, #20CAFE, #016D8D);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-left {
    transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
    transform: translateX(0);
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.social-container {
    margin: 20px 0;
}

.social-container a {
    border: 1px solid #DDDDDD;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}

.input-phone-number input:focus{
    outline: none !important;
    border:none !important;
    box-shadow: none !important;
    padding: 16 !important;
    font-size: medium !important;
}