@media only screen and (max-width: 800px) {
    .btn{
        display:none;
    }
    .srch
    {
        display:none;
    }
  }
  
 