@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');


html,
body,
#root,
.app {
    height: 100vh;
    width: 100vw;
    font-family: "Inter", sans-serif;
    box-sizing: border-box
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #8ad6fc; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  .app_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #191F45;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
